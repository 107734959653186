<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import * as Agents from "@/services/agents.js";
import appConfig from "@/app.config";
import Table from "@/components/elements/Tables";
import {required, email, numeric} from "vuelidate/lib/validators";
import swal from "sweetalert2";
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import tr from "vue2-datepicker/locale/es/tr";

export default {
  computed: {
    tr() {
      return tr
    }
  },
  page: {
    title: "Agents",
    meta: [{name: "agents", content: appConfig.description}],
  },
  components: {Layout, PageHeader, Table, Multiselect},
  created() {
    this.loadAgents();
  },

  data() {
    return {
      tableData: [],
      title: this.$t("menuitems.agents.text"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.agents.text"),
          href: "/Agents/all",
        },
        {
          text: this.$t("all"),
          active: true,
        },
      ],
      current_language: localStorage.getItem("language") || 'en',
      filterOn: [],
      sortBy: "name",
      fields: [
        {label: this.$t("name"), key: "name", sortable: true,formatter:(value)=>value || 'ــــــــ'},
        {label: this.$t("email"), key: "email", sortable: true,formatter:(value)=>value || 'ــــــــ'},
        {label: this.$t("phone"), key: "phone", sortable: true,formatter:(value)=>value || 'ــــــــ'},
        {label: this.$t("role"), key: "role", sortable: true,formatter:(value)=>value?this.$t('agent'):this.$t('admin')},
      ],
      addData: {
        name: "",
        phone: "",
        password: "",
        email: "",
        privileges: [],
        isAdmin: false,
      },
      options:["dashboard","agent","client","question","contract","consultant","contract_type"],

    };
  },
  validations: {
    addData: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
        numeric,
        phone: (value) => {
          // Validate that the phone number has exactly 11 numeric digits
          return /^[0-9]{11}$/.test(value);
        },
      },

      privileges: {
        required,
      },
      isAdmin: {
        required,
      },
    },
  },
  methods: {
    rowClicked(item) {
      this.$router.push({path: "/agent/" + item.id});
    },
    loadAgents() {
      Agents.GetAllAgents().then((data) => {
        this.tableData = data.agents;
      });
    },
    add() {
      this.$v.addData.$touch();

      if (this.$v.addData.$invalid) {
        return;
      } else {

        Agents.AddAgent(this.addData).then(() => {
          // console.log(data);
          this.$bvModal.hide("modal-add");
          this.successmsg();
          this.loadAgents();
          this.addData = {
            name: "",
            phone: "",
            password: "",
            email: "",
            privileges: [],
            isAdmin: false,
          }
          this.$v.addData.$reset();
        });
      }
    },
    successmsg() {
      swal.fire({
        position: "center",
        icon: "success",
        title: "Agent has been Added successfully",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    getRuleName(val){
      const optionsTitles ={
        dashboard:this.$t("menuitems.dashboard.text"),
        agent:this.$t("menuitems.agents.text"),
        client:this.$t("menuitems.clients.text"),
        question:this.$t("menuitems.questions.text"),
        contract:this.$t("menuitems.contracts.text"),
        consultant:this.$t("menuitems.consultant.text"),
        contract_type:this.$t("menuitems.contract_types.text"),
      }
      return optionsTitles[val] || this.$t('unknown')
    },
  },
  watch: {
    "addData.isAdmin"(newValue) {
      if (newValue === true) {
        // Perform your actions when myAttribute changes to 0
        this.addData.privileges = [
          "dashboard",
          "agent",
          "client",
          "question",
          "contract",
          "consultant",
          "contract_type",
        ];
      } else {
        this.addData.privileges = [];
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <Table
        :tableData="tableData"
        :fields="fields"
        :sortBy="sortBy"
        :filterOn="filterOn"
        :addbtn="true"
        @rowClicked="rowClicked"
        :filter-keys="['role']"
    />
    <b-modal
        no-close-on-backdrop
        id="modal-add"
        :title="$t('add_agent')"
        centered
        scrollable
        title-class="font-18"
    >
      <!-- <template #modal-ok> {{ $t("ok") }} </template>
      <template #modal-cancel> {{ $t("cancel") }} </template> -->
      <template #modal-footer>
        <div></div>
      </template>
      <form @submit.prevent="add()" ref="modal-add">
        <input
            type="text"
            :placeholder="$t('name')"
            v-model="addData.name"
            class="form-control mb-4"
            required
        />
        <input
            type="tel"
            :placeholder="$t('phone')"
            v-model="addData.phone"
            class="form-control mb-4"
            required
            :class="{
            'is-invalid': $v.addData.phone.$error,
          }"
        />
        <span class="invalid-feedback" v-if="!$v.addData.phone.phone"
        >please Enter Valid 8 digit phone number
        </span>
        <input
            type="email"
            :placeholder="$t('email')"
            v-model="addData.email"
            class="form-control mb-4"
            required
            :class="{
            'is-invalid': $v.addData.email.$error,
          }"
        />
        <input
            type="password"
            :placeholder="$t('password')"
            v-model="addData.password"
            class="form-control mb-4"
            required
        />
        <b-form-group label="isAdmin" v-slot="{ ariaDescribedby }">
          <b-form-radio
              v-model="addData.isAdmin"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              :value="true"
          >{{ $t('admin') }}
          </b-form-radio
          >
          <b-form-radio
              v-model="addData.isAdmin"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              :value="false"
          >{{$t('agent')}}
          </b-form-radio
          >
        </b-form-group>
        <div v-if="addData.isAdmin === false" class="mt-3">
          {{ $t("agent_roles") }}:
        </div>
        <multiselect v-model="addData.privileges"
                     v-if="addData.isAdmin === false"
                     :options="options"
                     :show-labels="false"
                     :placeholder="$t('agent_roles')"
                     :multiple="true"
                     :showLabels="false"
                     :hideSelected="true"
                     :custom-label="getRuleName"
                     :dir="current_language === 'ar'? 'rtl' : 'ltr'"
                     class="mb-3"
                     :class="{
            'is-invalid': $v.addData.privileges.$error,
          }"
        >
          <template #noResult>
            {{ $t('noResults') }}
          </template>
          <template #noOptions>
            {{ $t('noOptions') }}
          </template>
        </multiselect>
        <span class="invalid-feedback" v-if="$v.addData.privileges.$error"
        >{{ $t('pleaseSelect') }}</span
        >
        <b-row
        >
          <b-col cols="8"></b-col>
          <b-col cols="2"
          >
            <b-button @click="$bvModal.hide('modal-add')">{{
                $t("cancel")
              }}
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button variant="primary" type="submit">{{
                $t("ok")
              }}
            </b-button>
          </b-col
          >
        </b-row
        >
      </form>
    </b-modal>
  </Layout>
</template>
