var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('Table',{attrs:{"tableData":_vm.tableData,"fields":_vm.fields,"sortBy":_vm.sortBy,"filterOn":_vm.filterOn,"addbtn":true,"filter-keys":['role']},on:{"rowClicked":_vm.rowClicked}}),_c('b-modal',{attrs:{"no-close-on-backdrop":"","id":"modal-add","title":_vm.$t('add_agent'),"centered":"","scrollable":"","title-class":"font-18"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div')]},proxy:true}])},[_c('form',{ref:"modal-add",on:{"submit":function($event){$event.preventDefault();return _vm.add()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.name),expression:"addData.name"}],staticClass:"form-control mb-4",attrs:{"type":"text","placeholder":_vm.$t('name'),"required":""},domProps:{"value":(_vm.addData.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.addData, "name", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.phone),expression:"addData.phone"}],staticClass:"form-control mb-4",class:{
          'is-invalid': _vm.$v.addData.phone.$error,
        },attrs:{"type":"tel","placeholder":_vm.$t('phone'),"required":""},domProps:{"value":(_vm.addData.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.addData, "phone", $event.target.value)}}}),(!_vm.$v.addData.phone.phone)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("please Enter Valid 8 digit phone number ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.email),expression:"addData.email"}],staticClass:"form-control mb-4",class:{
          'is-invalid': _vm.$v.addData.email.$error,
        },attrs:{"type":"email","placeholder":_vm.$t('email'),"required":""},domProps:{"value":(_vm.addData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.addData, "email", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addData.password),expression:"addData.password"}],staticClass:"form-control mb-4",attrs:{"type":"password","placeholder":_vm.$t('password'),"required":""},domProps:{"value":(_vm.addData.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.addData, "password", $event.target.value)}}}),_c('b-form-group',{attrs:{"label":"isAdmin"},scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":true},model:{value:(_vm.addData.isAdmin),callback:function ($$v) {_vm.$set(_vm.addData, "isAdmin", $$v)},expression:"addData.isAdmin"}},[_vm._v(_vm._s(_vm.$t('admin'))+" ")]),_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":false},model:{value:(_vm.addData.isAdmin),callback:function ($$v) {_vm.$set(_vm.addData, "isAdmin", $$v)},expression:"addData.isAdmin"}},[_vm._v(_vm._s(_vm.$t('agent'))+" ")])]}}])}),(_vm.addData.isAdmin === false)?_c('div',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$t("agent_roles"))+": ")]):_vm._e(),(_vm.addData.isAdmin === false)?_c('multiselect',{staticClass:"mb-3",class:{
          'is-invalid': _vm.$v.addData.privileges.$error,
        },attrs:{"options":_vm.options,"show-labels":false,"placeholder":_vm.$t('agent_roles'),"multiple":true,"showLabels":false,"hideSelected":true,"custom-label":_vm.getRuleName,"dir":_vm.current_language === 'ar'? 'rtl' : 'ltr'},scopedSlots:_vm._u([{key:"noResult",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noResults'))+" ")]},proxy:true},{key:"noOptions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noOptions'))+" ")]},proxy:true}],null,false,725877946),model:{value:(_vm.addData.privileges),callback:function ($$v) {_vm.$set(_vm.addData, "privileges", $$v)},expression:"addData.privileges"}}):_vm._e(),(_vm.$v.addData.privileges.$error)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.$t('pleaseSelect')))]):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}}),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{on:{"click":function($event){return _vm.$bvModal.hide('modal-add')}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")])],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }