import axios from "axios";
import Swal from "sweetalert2";
// Define the URL of your Firebase Cloud Function
const firebaseFunctionURL =
  "https://us-central1-law-office-flutterflow.cloudfunctions.net/app/agents";

export function GetAllAgents() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${firebaseFunctionURL}`)
      .then((res) => {
        // console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error getting agents:", err);
        message(err.response.data.msg);

        reject(err);
      });
  });
}
export function AddAgent(Data) {
  console.log(Data);
  return new Promise((resolve, reject) => {
    axios
      .post(`${firebaseFunctionURL}`, Data)
      .then((res) => {
        // console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error Adding agent:", err);

        message(err.response.data.msg);

        reject(err);
      });
  });
}
export async function GetAgentById(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${firebaseFunctionURL}/${id}`)
      .then((res) => {
        // console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error getting agent:", err);
        message(err.response.data.msg);

        reject(err);
      });
  });
}

export async function UpdateAgent(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${firebaseFunctionURL}/${id}`, data)
      .then((res) => {
        // console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error updating agent:", err);
        message(err.response.data.msg);

        reject(err);
      });
  });
}
export async function DeleteAgent(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${firebaseFunctionURL}/${id}`)
      .then((res) => {
        // console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error deleting agent:", err);
        message(err.response.data.msg);
        reject(err);
      });
  });
}

function message(msg) {
  Swal.fire({
    position: "center",
    icon: "error",
    title: msg,
    showConfirmButton: false,
    timer: 1000,
  });
}
