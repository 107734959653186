<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  props: {
    sortBy: {type: String, default: ""}, //inital table sorting
    tableData: {type: Array, default: () => []}, //table data
    fields: {Type: Array, default: () => []}, //fields or columns headers
    filterOn: {Type: Array, default: () => []}, // if have filter non initial state
    addbtn: {Type: Boolean, default: false},
    hideAddButton: {Type: Boolean, default: false},
    hideShowMore: {Type: Boolean, default: false, required: false},
    filterKeys: {Type: Array, default: () => [], required: false},
    hasAnnon: {Type: Boolean, required: false, default: false}

  },
  watch: {
    // Watch for changes in the 'tableData' prop
    tableData: function () {
      // Update 'isBusy' to false when 'tableData' changes
      this.isBusy = false;
    },
  },
  created() {
    const obj = {}
    this.filterKeys.forEach(el => {
      obj[el] = null
    });
    this.selectVal = {...obj}
    if (!this.hideShowMore) {
      this.finalFields = [...this.fields, {
        label: '',
        key: "actions",
        sortable: false,
      }]
    } else {
      this.finalFields = [...this.fields]
    }
    if (this.tableData.length > 0) {
      // if table have data in the inital state it dont have to be busy
      this.isBusy = false;

    }
    this.tableSort = this.sortBy
  },
  data() {
    return {
      finalFields: [],
      ASCOrDESC:[false,true],
      totalRows: 1,
      isBusy: true,
      currentPage: 1,
      tableSort:'',
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortDesc: true,
      selectVal: {},
      current_language: localStorage.getItem("language") || 'en',

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.filteredItems.length;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
    },
    // filter data
    filteredItems() {
      return this.tableData.filter(item => {
        let keep = true;
        this.filterKeys.forEach(key => {
          keep = keep && (
              this.selectVal[key] === null ||
              !this.selectVal[key]?.length ||
              this.selectVal[key].some(el => item[key] === el.val)
          )
        });
        return keep;
      });
    },
    options() {
      const contractStatu = {
        0: this.$t("not_reviewed"),
        1: this.$t("reviewed"),
        2: this.$t("paid")
      }
      const labels = {
        'clientName': (el) => el !== 'ــــــــ' ? el : this.hasAnnon ? this.$t('anonymous') : this.$t('noName'),
        'duration': (el) => el ?? this.$t('busyTime'),
        'isPaid': (el) => el ? this.$t("paid") : this.$t("not_paid"),
        'contractStatus': (el) => contractStatu[el] || this.$t("closed"),
        'role': (el) => el ? this.$t('agent') : this.$t('admin')
      }
      const options = {};
      this.filterKeys.forEach(key => {
        const vals = this.tableData.map(item => item[key]);
        options[key] = Array.from(new Set(vals));
        options[key] = options[key].map(el => ({
          val: el,
          name: labels[key] ? labels[key](el) : el
        }))
      });
      return options;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowClicked(item) {
      //console.log(item);
      this.$emit("rowClicked", item);
    },
    pageChanged(item) {
      this.$emit("pageChanged", {page: item, itemsPerPage: this.perPage});
    },
    getHeadName(key) {
      if (key === 'isAnonymous') {
        return 'isAnonymous'
      }
      const element = this.fields.find(el => el.key === key)
      return element?.label || ''
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <!-- show number of rows per page section -->
                <label class="d-inline-flex align-items-center">
                  {{ $t("show") }}&nbsp;
                  <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                  ></b-form-select
                  >&nbsp;{{ $t("entries") }}
                </label>
              </div>
            </div>
            <!-- Search section-->
            <div class="col-sm-12 col-md-6">
              <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  {{ $t("search") }}:
                  <b-form-input
                      v-model="filter"
                      type="search"
                      :placeholder="$t('search') + '...'"
                      class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
                <b-button
                    :disabled="!addbtn"
                    class="add-button"
                    v-b-modal.modal-add
                    variant="success"
                    v-if="!hideAddButton"
                >+
                </b-button
                >
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="filter-container">
            <div v-for="(key,index) in filterKeys" :key="`filterOfIndex.${index}`">
              <b>{{ getHeadName(key) }}</b>
              <multiselect
                  v-model="selectVal[key]"
                  :options="options[key]"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="true"
                  placeholder="--"
                  :max-height="150"
                  :hideSelected="true"
                  :multiple="true"
                  :dir="current_language === 'ar'? 'rtl' : 'ltr'"
                  style="max-width: 100%"
                  :selectLabel="$t('pressToSelect')"
                  :deselectLabel="$t('pressToDeselect')"
                  :selectedLabel="$t('selected')"
                  label="name"
                  track-by="name"
              >
                <template #noResult>
                  {{ $t('noResults') }}
                </template>
                <template #noOptions>
                  {{ $t('noOptions') }}
                </template>
              </multiselect>
            </div>
            <b-form-group
                :label="$t('sort')"
                class="mb-0 table-sort-container"
                v-slot="{ ariaDescribedby }"

            >
              <b-input-group >
                <b-form-select
                    id="sort-by-select"
                    v-model="tableSort"
                    :options="sortOptions"
                    :aria-describedby="ariaDescribedby"
                    class="w-75"
                >
                  <template #first>
                    <option value="">--</option>
                  </template>
                </b-form-select>
                <b-form-select
                    v-model="sortDesc"
                    :disabled="!tableSort"
                    :aria-describedby="ariaDescribedby"
                    class="w-25"
                >
                  <option :value="false">{{ $t('asc') }}</option>
                  <option :value="true">{{ $t('desc') }}</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
                :busy="isBusy"
                :items="filteredItems"
                :fields="finalFields"
                responsive="sm"
                head-variant="dark"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="tableSort"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                hover
                striped
                @filtered="onFiltered"
            >
              <!-- busy state section -->
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <!-- if have status Bool it will render as badge with success or danger  -->
              <template #cell(status)="data">

                <div
                    class="badge font-size-12"
                    :class="
                    data.item.status
                      ? 'badge-soft-success'
                      : 'badge-soft-warning'
                  "
                >
                  {{ data.value }}
                </div>
              </template>
              <!-- temp cell for contract status -->
              <template #cell(contractStatus)="data">
                <div
                    class="badge  font-size-12"
                    :class="{
                      'badge-soft-warning':data.item.contractStatus === 0,
                      'badge-soft-info':data.item.contractStatus === 1,
                      'badge-soft-success':data.item.contractStatus === 2,
                      'badge-soft-danger':data.item.contractStatus === 3,
                      'badge-soft-secondary': ![0,1,2,3].includes(data.item.contractStatus)
                  }"
                >
                  {{ data.value }}
                </div>
              </template>
              <template #cell(isPaid)="data">
                <template v-if="data.value !== 'ــــــــ'">
                  <div
                      class="badge font-size-12"
                      :class="
                    data.item.isPaid
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                  >
                    {{ data.value }}
                  </div>
                </template>
                <template v-else>
                  <div class="badge font-weight-lighter font-size-12">{{ data.value }}</div>
                </template>
              </template>
              <template #cell(QuestionStatus)="data">
                <div
                    class="badge font-size-12"
                    :class="
                    data.item.QuestionStatus
                      ? 'badge-soft-success'
                      : 'badge-soft-danger'
                  "
                >
                  {{ data.value }}
                </div>
              </template>
              <!-- if you want to have actions column -->
              <template #cell(edit)="data">
                <b-button
                    pill
                    @click="$emit('editClicked', data)"
                    variant="warning"
                ><i class="ri-pencil-fill"></i
                ></b-button>
              </template>
              <!--  -->
              <template #cell(actions)="data">
                <b-button variant="dark" size="sm" @click="rowClicked(data.item)">
                  <i class="mdi mdi-eye-outline"></i>
                </b-button>
              </template>
              <template #cell(clientName)="data">
                <template v-if="data.item.clientId">
                  <template v-if="data.item?.isAnonymous">
                    {{ $t('anonymous') }}
                  </template>
                  <template v-else>
                    <router-link class="client-link" :to="{name:'clientPage',params:{id:data.item.clientId}}">
                      {{ data.item.clientName }}
                    </router-link>
                  </template>
                </template>
                <template v-else>
                  {{ data.item.clientName }}
                </template>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                  class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      @change="pageChanged"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.add-button {
  margin-inline-start: 10px;
  height: 29px;
  padding-top: 0;
  padding-bottom: 0
}
.filter-container {
  display: grid;
  gap: 12px;
  margin-bottom: 12px;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 767px) {
  .filter-container {
    grid-template-columns: 1fr;
  }
}
</style>
<style>
.table-sort-container .col-form-label{
  padding: 0;
  font-weight: bolder;
}
.table-sort-container select{
  box-shadow: none!important;
}
</style>